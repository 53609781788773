<template>
    <div class="sup_content Scan-list">
        <div class="sup_page_heading">
            <h2>Accessability Scan Management</h2>
            <Button v-if="createScan" icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add Scan'" @click="scanModal.addScan = true" />
        </div>
        <div class="sup_inner_content">
            <!--- Show Scan Table Start --->
            <DataTable class="p-datatable" :paginator="true" v-if="allScanData.data" :value="allScanData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allScanData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading customers data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button v-if="scanDeactiveList" :label="allScanData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allScanData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allScanData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllScan(allScanData.activityStatus, 'scanStatus')" />
                            <Button v-if="allScanData.activityStatus !== 'active'" label="Delete All" icon="pi pi-trash" class="p-button-danger"  />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allScanData.search" placeholder="Search Scan" @keyup.enter="fetchAllScan(allScanData.activityStatus, 'search')"/>
                            </span>
                            <Button v-if="allScanData.search !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllScan(allScanData.activityStatus, 'resetFilters')"/>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No customers found.
                </template>
                <Column field="websiteUrl" header="Website Url" headerStyle="width: 12rem" :sortable="true"></Column>

                <Column field="customer" header="Customer" headerStyle="width: 17rem" :sortable="true">
                    <template #body="slotProps" v-if="allScanData.data">
                        {{slotProps.data.User.name}}<br>
                        {{slotProps.data.User.email}}
                    </template>
                </Column>

                <Column field="scanCount" header="Total Scan" :sortable="true"></Column>

                <!-- <Column field="isScanEnabled" header="Is Scan Enabled " :sortable="true">
                    <template #body="slotProps">
                        <label v-if="slotProps.data != null" :for="slotProps.data.uuid" :class="['sup_status_switch', slotProps.data.isScanEnabled ? 'sup_status_active' : 'sup_status_inactive', slotProps.data.deletedAt !== null ? 'sup_status_switch_disable' : '']" >
                            <input v-if="slotProps.data.isScanEnabled" type="checkbox" role="switch" :id="slotProps.data.uuid" checked>
                            <input v-else type="checkbox" role="switch" :id="slotProps.data.uuid">
                            <span class="sup_switch_label" data-active="Yes" data-inactive="No" @click="switchIsScanEnabled(slotProps.data.uuid, slotProps.data.isScanEnabled, $event)"></span>
                            <span class="sup_switch_handle"></span>
                        </label>
                    </template>
                </Column> -->

                <Column field="lastScanDate" header="Last Scan Date" :sortable="true">
                    <template #body="slotProps">
                        {{usFormatDate(slotProps.data.lastScanDate)}}
                    </template>
                </Column>
                <Column field="scanAllowed" header="Scan Allowed" style="text-align: center" :sortable="false">
                    <template #body="slotProps">
                        <!-- <label for="toggle_button">
                            <span v-if="slotProps.data.scanEnabledByAdmin">On</span>
                            <span v-if="!slotProps.data.scanEnabledByAdmin">Off</span>

                            <input type="checkbox" id="toggle_button">
                        </label> -->
                        <label class="switch">
                            <input :data-value="slotProps.data.scanEnabledByAdmin" type="checkbox" role="switch" :id="slotProps.data.id" :checked="slotProps.data.scanEnabledByAdmin" @click="enableDisableScan(slotProps.data)">
                            <span class="slider round"></span>
                        </label>
                    </template>
                </Column>
                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <!-- <Button icon="pi pi-play" class="p-button-warning p-button-rounded p-button-sm p-mr-1" v-tooltip="'Rescan'" @click="confimationModal(slotProps.data)" /> -->
                        <Button v-if="slotProps.data.status !== 'failed'" type="button" icon="pi pi-download" class="p-button-sm p-button-rounded p-button-secondary p-mr-1" @click="downloadScan(slotProps.data)" v-tooltip.left="'Download Scan'" />
                        <Button v-if="scanDeactive ? slotProps.data.deletedAt === null : false" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="deleteRestoreModal(slotProps.data, 'delete')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-undo" class="p-button-success p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore'" @click="deleteRestoreModal(slotProps.data, 'restore')" />
                    </template>
                </Column>
                
            </DataTable>
            <!--- Show Scan Table End --->
        </div>
        <!--- Add Scan Modal Start --->
        <Dialog header="Add New Scan" v-model:visible="scanModal.addScan" :modal="true" :style="{width: '600px'}" @hide="closeModal()">
            <form class="sup_customer_add" @submit.prevent="createScan()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="u_name">Website Url<span class="text-danger">*</span></label>
                        <InputText v-model="addEditScan.websiteUrl" id="u_url" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.websiteUrlEmpty">Website Url is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allScanData.addStatus === 200 ? 'success' : 'error']" v-if="allScanData.addStatus !== ''">{{allScanData.addMessage}}</div >
            </form>
        </Dialog>
        <!--- Add Scan Modal End --->
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="dltRestoreModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="dltRestoreModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteScan(dltRestoreModal.modaldata.data, dltRestoreModal.modaldata.load), dltRestoreModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->
        <!---Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="reScanDetail(confirmModal.modaldata.data), confirmModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!---Confirmation End --->
        <!-- Toast to show notification of events start -->
        <Toast position="top-right" />
        <!-- Toast to show notification of events end -->
    </div>
</template>

<script>
import { reScanDetails, allScanDetails, addScanDetails, deleteScanDetails, downloadScanDetails } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import {currentMenuUuid} from '../../../../helper/pageActions/pageActions';
import userLogout from '../../../../helper/userLogout/userLogout';
import { formatDate, usFormatDate } from '../../../../helper/utility/utility';
import axios from "axios";
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
import { useRoute } from "vue-router";
const FileSaver = require('file-saver');
import { reactive, onBeforeMount, computed } from 'vue';

export default {
    name: 'accessabilityScanManagement',
    setup(){
        //Vuex store data call fucntion
        const store = useStore();

        //Route call
        const route = useRoute();

         //Toast call
        const toast = useToast();

        console.log(route.params.id);

        //User privilages role, UUID and actions store
        const pagePrivilages = reactive({
            userRole: '',
            uuid: '',
            actions: ''
        });

        //Customer initial data storing state
        const allScanData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'all',
            search: null,
            dateRange: null,
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: '',
        });

        //All modal open/close handling state
        const scanModal = reactive({
            addScan: false
        });

        //ADD and EDIT Customer input binding state
        const addEditScan = reactive ({
            websiteUrl : ''
        });

        //Form validation error state
        const addEditvalidationError = reactive({
            websiteUrlEmpty: false
        });

        //Delete/restore item corfirmation state
        const confirmModal = reactive({
            modalState: false,
            modaldata: '',
            dltState: false,
            dltdata: '',
            uuid: ''
        });

        const dltRestoreModal = reactive({
            modalState: false,
            modaldata: '',
            uuid: ''
        })

        //Coditional page action state update to show action conditionally
        const scanAdd = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.scanmanagementAdd);
        const scanDeactive = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.scanmanagementDeactivate);
        const scanDeactiveList = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.scanmanagementDeactivateList);

        /**
         * Self calling fucntion on compement initiation
         */
        (async () => {
            let { userRole, menuActions } = await currentMenuUuid('ScanManagement');
            pagePrivilages.userRole = userRole;
            pagePrivilages.actions = menuActions;
        })();

        /**
         * On component get mounted require functions call
         */
        onBeforeMount( async() => {
            fetchAllScan();
        });

        /**
         * Fetching all Customers from DB
         * intially fetching active Customers but this fucntion user can get deactive Customer and filter Customers by date and name
         * @param {scanStatus} getting customer list current state(ex: active/deactive)
         * @param {load} from where function calling
         */
        const fetchAllScan = async (scanStatus, load) => {
            let date = null;
            //Toggle Customer status to fetch active/deactive role
            if(load === 'scanStatus'){
                allScanData.activityStatus = scanStatus === 'active' ? 'de-active' : 'active' ;
            }

            //To clear search and filter then fetch all data
            if(load === 'resetFilters'){
                allScanData.search = null;
                allScanData.dateRange = null;
            }

            //Filter date wise code
            if (load === 'dateRange') {
                date = formatDate(allScanData.dateRange[0]) + ' ' + formatDate(allScanData.dateRange[1]);
            }

            //API call start
            try{
                allScanData.loading = !allScanData.loading;
                const response = await axios.get( allScanDetails, {
                    params: {
                        status: allScanData.activityStatus,
                        search: allScanData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                    allScanData.data = response.data.data.websitescan;
                    allScanData.total = response.data.data.total;
                    allScanData.loading = !allScanData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        /**
         * Fucntion is for add new customer and data pass to api
         * After API success calling customer list again by fetchAllScan()
         * And reseting form value
         */
        const createScan = async () => {
            const isValidate = fromValidation();
            if (isValidate) {
                try{
                    const response = await axios.post( addScanDetails,{
                        websiteUrl: addEditScan.websiteUrl
                    },{
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }, 
                    });
                    if(response.status === 200) {
                        allScanData.addStatus = response.data.status;
                        allScanData.addMessage = response.data.data;
                        fetchAllScan();
                        addEditFormReset();
                    }
                } catch(err){
                    allScanData.addStatus = err.response.data.status;
                    allScanData.addMessage = err.response.data.message;
                }
            }
        }

        /**
         * Re Scan data by this function
         * @{UUID} pass respective customer unique ID
         */
        const reScanDetail = async (slotData) => {
            try{
                const options = {
                    method: 'put',
                    url: reScanDetails,
                    params: {
                        uuid: slotData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                };
                let response = await axios(options);
                if (response.status === 200) {
                    allScanData.editStatus = response.data.status;
                    allScanData.editMessage = response.data.message;
                    fetchAllScan('active');
                } else if(response.status === 400) {
                    toast.add({severity:'danger', summary: 'Re Scan Notification', detail: response.message, life: 5000});
                }
                
            } catch(err){
                allScanData.editStatus = err.response.data.status;
                allScanData.editMessage = err.response.data.message;
            }
        }

        /**
         * Delete Scan
         * On success fetch all cutomer data again to update list
         */
        const deleteScan = async(sloteData, type) => {
            try {
                const options = {
                        method: type === 'delete' ? 'delete' : 'patch',
                        url: deleteScanDetails,
                        params: {
                            uuid : sloteData.uuid
                        },
                        headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                    };
                let response = await axios(options);
                console.log(response);
                fetchAllScan('active');
            } catch(err){
                console.log(err);
            }
        }

        /**
         * This fucntion is for Downloading invoice
         * @param {slotData} slected item data
         */
        const downloadScan = async(slotData) =>{
            const response = await axios.get( downloadScanDetails, {
                responseType: 'arraybuffer',
                params: {
                    uuid: slotData.uuid
                },headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if(response.status === 200) {
                const dirtyFileName = response.headers['content-disposition'];
                const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                var fileName = dirtyFileName.match(regex)[3];
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver(blob, fileName);
            }
        }

        /**
         * All add/edit form validation
         */
        const fromValidation = () => {

            if (!addEditScan.websiteUrl) {
                addEditvalidationError.websiteUrlEmpty = true;
            } else {
                addEditvalidationError.websiteUrlEmpty = false;
            }

            if (!addEditvalidationError.websiteUrlEmpty) {
                return true;
            } else {
                return false;
            }
        }

        //Form reset
        const addEditFormReset = () => {
            addEditScan.websiteUrl = ''
        }

        //All modal after close function call
        const closeModal = () => {
            addEditFormReset();
            allScanData.addStatus= '',
            allScanData.addMessage= '',
            allScanData.editStatus = '';
            allScanData.editMessage = '';
        }

        /**
         * Confirmation on delete/restore
         */
        const confimationModal = (sloteData) => {
            confirmModal.modalState = true;
            confirmModal.modaldata = {
                data: sloteData
            }
        }

        const deleteRestoreModal = (sloteData, load) => {
            dltRestoreModal.modalState = true;
            dltRestoreModal.modaldata = {
                data: sloteData,
                load: load
            }
        }

        const enableDisable = {
            uuid: null,
            value: null
        }
        
        const enableDisableScan = async (slotProps) => {
            try {
                console.log('current',slotProps.scanEnabledByAdmin);
                console.log(slotProps.uuid);
                const response = await axios.patch( allScanDetails+"/allow", {
                    scanEnabledByAdmin: !slotProps.scanEnabledByAdmin
                }, {
                    params: {
                        uuid: slotProps.uuid
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                if(response.status === 200) {
                    toast.add({severity:'warn', summary: 'No Action Needed', detail: response.data.data, life: 5000});
                } else if(response.status === 201) {
                    toast.add({severity:'success', summary: response.data.message, detail: response.data.data, life: 5000});
                    allScanData.data.forEach((entry) => {
                        if(entry.uuid === slotProps.uuid)
                            entry.scanEnabledByAdmin = !slotProps.scanEnabledByAdmin
                    })
                    console.log(allScanData.data)
                }
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        }


        //Returing variables to template
        return{
            scanAdd,
            deleteScan,
            confirmModal,
            reScanDetail,
            pagePrivilages,
            allScanData,
            scanModal,
            addEditScan,
            addEditvalidationError,
            createScan,
            closeModal,
            fetchAllScan,
            confimationModal,
            downloadScan,
            scanDeactiveList,
            dltRestoreModal,
            deleteRestoreModal,
            scanDeactive,
            usFormatDate,
            enableDisableScan,
            enableDisable
        }
    }
}
</script>

<style lang="scss">
.p-tieredmenu{
    width: 141px;
}
.sub_customer_view{
    .p-dialog-header-icons{
        display: none;
    }
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 14px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
<style lang="scss" scoped>
@import "../../../../assets/style/component/superAdmin/pages/customer/CustomerList";
</style>